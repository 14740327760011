export default {
  props: {
    module: { type: Object, default: () => ({}) },
    value: { type: Object, default: () => ({}) }
  },
  created () {
    if (this.module.id) {
      this.form = { ...this.form, ...this.module }
    }
    this.$emit('input', { ...this.form })
  }
}
